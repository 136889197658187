import React from 'react';
import './App.css';
import Grid from '@mui/material/Grid';
import ClickableCard from './ClickableCard';
import Header from './Header';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme(); // Creating a default theme

function App() {
  const handleClick = (url) => {
    window.location.href = url;
  };
  return (
    <>
      <Header></Header>
      <div className="App">
        <Grid container spacing={40}>
          <Grid item>
          <ThemeProvider theme={theme}>
            <ClickableCard
              title="Submission Intake"
              description="Click me to open a new tab!"
              linkUrl="https://submissionintake.digitalinsightsengine.com/"
            /></ThemeProvider>
          </Grid>
          <Grid item>
            <ClickableCard
              title="UW Claims Note Summarization"
              description="Click me to open another tab!"
              linkUrl="https://claimssummary.digitalinsightsengine.com/"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );

}

export default App;


// // src/App.js <LoginButton />
// import React from 'react';
// import { Routes, Route } from 'react-router-dom';

// import Callback from './Callback';
// import LoginButton from './LoginButton';

// function App() {
//   return (
//     <div className="App">
//     <Routes>
//       <Route path="/callback" component={Callback} />
//       <Route exact path="/" component={LoginButton} />
//     </Routes>
//     </div>
//   );
// }

// export default App;