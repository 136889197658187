import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const styles = {
  card: {
    width: '25rem',
    color: 'white',
    backgroundColor: '#0078D4',
    marginTop: '7rem',
    marginLeft: '-1rem',
    height: '32rem',
    borderRadius: '2.5rem',
    transition: 'transform 0.3s',
  },
  title: {
    textAlign: 'center',
    height: '20rem',
    marginTop: '11.5rem',
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  hoverTitle: {
    color: '#97d42a',
    fontSize: '2.15rem',
  },
};

const ClickableCard = ({ title, description, linkUrl, auth }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openNewTab = (linkUrl) => {
    window.open(linkUrl, '_blank');
  };

  return (
    <Card
      style={styles.card}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardActionArea onClick={() => openNewTab(linkUrl)}>
        <CardContent>
          <Typography
            variant="h5"
            style={{ ...styles.title, ...(isHovered && styles.hoverTitle) }}
          >
            {title}
          </Typography>
          {/* <Typography variant="body2" color="textSecondary">
            {description}
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ClickableCard;
