import { Button } from "@mui/material";
import React from "react";
import DeloitteLogo from "./Deloitte_logo_ui_react.png"

function Header() {

  return (
    <div style={{marginTop:'-10rem'}}>
      <div style={{marginLeft:'-23.5rem'}}>
        <img
          className="image"
          src={DeloitteLogo}
          alt="Deloitte Logo"
          style={{height:"42px"}}
        ></img>
      </div>
      <div style={{marginTop:'-5rem',color:'white',marginLeft:'16rem',fontSize:'1.25rem'}}>
        <h1>Generative AI Use Case Solutions</h1>
      </div>
      <div>
        {/* <Button variant="outlined" onClick={signOut}>
          Sign out
        </Button> */}
      </div>
    </div>
  );
}
export default Header;
